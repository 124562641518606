import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  ImageListItem,
  Paper,
  Typography,
} from "@mui/material";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import AccountHeader from "./AccountHeader";
import Preview from "./Preview";
import NoData from "../../components/common/NoData";
import GettingStarted, { setupItems } from "./GettingStarted";
import ImagePreviewModal from "../../components/common/ImagePreviewModal";

const sliderProperties = {
  prevArrow: (
    <IconButton style={{ left: -40 }}>
      <KeyboardArrowLeftIcon />
    </IconButton>
  ),
  nextArrow: (
    <IconButton style={{ right: -40 }}>
      <KeyboardArrowRightIcon />
    </IconButton>
  ),
};

export default function Profile() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { restaurant } = useContext(AppContext);
  const currentStepIndex = 2;
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  if (!restaurant) {
    return null;
  }

  return (
    <div>
      <AccountHeader />
      <Preview
        isOpen={isPreviewOpen}
        restaurant={restaurant}
        setIsOpen={setIsPreviewOpen}
      />
      <ImagePreviewModal
        isOpen={!!currentImage}
        handleClose={() => setCurrentImage("")}
        url={currentImage}
      />
      <Container maxWidth="xl">
        <Grid container marginTop={4}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">{restaurant.name}</Typography>
            <Typography variant="body1">{restaurant.address}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} className="justify-end text-right">
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setIsPreviewOpen(true)}
            >
              {t("account.previewAction")}
            </Button>
          </Grid>
        </Grid>
        <Grid container marginTop={4}>
          <Grid item xs={12} sm={7}>
            <Box marginRight={2}>
              <Paper elevation={3} className="p-8">
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">
                      {t("account.accountInfoTitle")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} className="justify-end text-right">
                    <Link to="/account/profile/edit">
                      <Button variant="contained" color="secondary">
                        {t("common.edit")}
                      </Button>
                    </Link>
                  </Grid>
                </Grid>

                <Box marginTop={6}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        {t("account.restaurantName")}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className="justify-end text-right"
                    >
                      <Typography variant="body2">{restaurant.name}</Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ marginTop: 2 }} />
                </Box>
                <Box marginTop={4}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        {t("account.restaurantBio")}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className="justify-end text-right"
                    >
                      <Typography variant="body2">{restaurant.bio}</Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ marginTop: 2 }} />
                </Box>
                <Box marginTop={4}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        {t("account.restaurantCuisine")}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className="justify-end text-right"
                    >
                      <Typography variant="body2">
                        {restaurant.cuisine}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ marginTop: 2 }} />
                </Box>
                <Box marginTop={4}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        {t("account.restaurantWebsite")}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className="justify-end text-right"
                    >
                      <Typography variant="body2">
                        {restaurant.website}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ marginTop: 2 }} />
                </Box>
                <Box marginTop={4}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        {t("account.restaurantPhone")}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className="justify-end text-right"
                    >
                      <Typography variant="body2">
                        {restaurant.phone}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
              <Box marginY={2}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{ paddingX: 4, py: 2 }}
                  >
                    <Typography variant="body2">
                      {t("account.gettingStartedText", {
                        current: currentStepIndex,
                        total: setupItems.length,
                      })}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ paddingX: 4 }}>
                    <GettingStarted
                      restaurant={restaurant}
                      currentStepIndex={currentStepIndex}
                    />
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Paper elevation={3} className="p-8 sm:ml-4">
              {restaurant.photos.length ? (
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">
                      {t("account.photoGalleryTitle")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} className="justify-end text-right">
                    <Link to="/account/profile/photo-gallery">
                      <Button
                        variant="contained"
                        color="secondary"
                        type="button"
                      >
                        {t("account.manageAction")}
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              ) : null}
              <Box marginTop={4} padding="0 30px">
                {restaurant.photos.length ? (
                  <Slide
                    slidesToScroll={2}
                    slidesToShow={2}
                    {...sliderProperties}
                  >
                    {restaurant.photos.map((item, index) => (
                      <div className="each-slide-effect" key={index}>
                        <ImageListItem>
                          <img
                            role="button"
                            onClick={() => setCurrentImage(item.img)}
                            srcSet={`${item.img}?w=200&h=200&fit=crop&auto=format&dpr=2 2x`}
                            src={`${item.img}?w=200&h=200&fit=crop&auto=format`}
                            alt={item.title}
                            loading="lazy"
                          />
                        </ImageListItem>
                      </div>
                    ))}
                  </Slide>
                ) : (
                  <NoData
                    title={t("photoGallery.noPhotosTitle")}
                    message={t("photoGallery.noPhotosText")}
                    topMargin="0"
                    actionTitle={t("photoGallery.addPhotos")}
                    action={() => navigate("/account/profile/photo-gallery")}
                  />
                )}
              </Box>
            </Paper>
            <Paper elevation={3} className="p-8 sm:ml-4 mt-4">
              <Box>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">
                      {t("account.arrivalInstructions")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={5} className="justify-end text-right">
                    <Typography variant="body2">
                      {restaurant.arrivalInstructions}
                    </Typography>
                  </Grid>
                  <Grid item xs={0} sm={1} className="justify-end text-right">
                    <Link to="/account/profile/edit#arrival-instructions">
                      <IconButton>
                        <ChevronRightIcon sx={{ fontSize: 24 }} />
                      </IconButton>
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <Paper elevation={3} className="p-8 sm:ml-4 mt-4">
              <Box>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">
                      {t("account.cancellationPolicy")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={5} className="justify-end text-right">
                    <Typography variant="body2">
                      {restaurant.cancellationPolicy}
                    </Typography>
                  </Grid>
                  <Grid item xs={0} sm={1} className="justify-end text-right">
                    <Link to="/account/profile/edit#cancellation-policy">
                      <IconButton>
                        <ChevronRightIcon sx={{ fontSize: 24 }} />
                      </IconButton>
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
