import {
  Box,
  Button,
  FormControl,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";

import profileIcon from "../../../assets/images/getting-started-profile.svg";
import profileActiveIcon from "../../../assets/images/getting-started-profile-active.svg";
import menuIcon from "../../../assets/images/getting-started-menu.svg";
import resyIcon from "../../../assets/images/getting-started-resy.svg";
import photosIcon from "../../../assets/images/getting-started-photos.svg";
import doneIcon from "../../../assets/images/getting-started-done.svg";
import menuActiveIcon from "../../../assets/images/getting-started-menu-active.svg";
import resyActiveIcon from "../../../assets/images/getting-started-resy-active.svg";
import photosActiveIcon from "../../../assets/images/getting-started-photos-active.svg";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Restaurant } from "@/app/types/Restaurant";
import { RestaurantPlatforms } from "../../constants";
import { useState } from "react";

export const setupItems = [
  {
    id: "addPhotos",
    icon: photosIcon,
    iconActive: photosActiveIcon,
    to: "/account/profile/photo-gallery",
  },
  {
    id: "setupMenu",
    icon: menuIcon,
    iconActive: menuActiveIcon,
    to: "/account/menu",
  },
  {
    id: "arrivalInstructions",
    icon: profileIcon,
    iconActive: profileActiveIcon,
    to: "/account/profile/edit#arrival-instructions",
  },
  {
    id: "cancellationPolicy",
    icon: profileIcon,
    iconActive: profileIcon,
    to: "/account/profile/edit#cancellation-policy",
  },
  {
    id: "connectPlatform",
    icon: resyIcon,
    iconActive: resyActiveIcon,
    action: () => {
      console.debug(new Error("not implemented"));
    },
  },
];

const SetupStage = function ({
  icon,
  stepId,
  action,
  isCurrentStep,
  isPastStep,
  to,
}: {
  icon: string;
  stepId: string;
  action?: () => void;
  isCurrentStep?: boolean;
  isPastStep?: boolean;
  to?: string;
}) {
  const { t } = useTranslation();
  const [restaurantPlatform, setRestaurantPlatform] = useState(
    RestaurantPlatforms.Resy,
  );
  const canNavigate = to && (isCurrentStep || isPastStep);
  return (
    <Paper sx={{ mt: 2, p: 2, borderRadius: 2 }} elevation={3}>
      <div className="flex gap-2 items-center py-2">
        <div className="pr-4 pl-2">
          <img
            src={isPastStep ? doneIcon : icon}
            width={56}
            alt={t(`home.getStarted-${stepId}`)}
            style={{ fill: "red", stroke: "red", color: "red" }}
          />
        </div>
        <div className="flex-1">
          <Typography variant="h6">{t(`home.getStarted-${stepId}`)}</Typography>
          <Typography variant="body2">
            {t(`home.getStartedDescription-${stepId}`)}
          </Typography>
          {stepId === "connectPlatform" && (
            <div className="mt-2 pr-4">
              <FormControl fullWidth disabled={!isPastStep && !isCurrentStep}>
                <Select
                  value={restaurantPlatform}
                  onChange={(e) =>
                    setRestaurantPlatform(e.target.value as RestaurantPlatforms)
                  }
                >
                  {Object.values(RestaurantPlatforms).map((i) => (
                    <MenuItem value={i} key={i}>
                      {t(`restaurantPlatforms.platform-${i}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </div>

        <div className="pr-4">
          <Link
            to={canNavigate ? to : "#"}
            target={canNavigate ? "_blank" : "_self"}
          >
            <Button
              disabled={!isPastStep && !isCurrentStep}
              variant="contained"
              onClick={action}
            >
              {t(
                !isPastStep || isCurrentStep
                  ? `home.getStartedAction-${stepId}`
                  : "common.edit",
              )}
            </Button>
          </Link>
        </div>
      </div>
    </Paper>
  );
};

export default function GettingStarted({
  restaurant,
  currentStepIndex = 0,
}: {
  restaurant?: Restaurant;
  currentStepIndex: number;
}) {
  const progress = (currentStepIndex * 100) / setupItems.length;
  return (
    <Box>
      <Box pb={2}>
        <div className="flex gap-2 items-center">
          <div className="flex-1">
            <LinearProgress variant="determinate" value={progress} />
          </div>
          <Typography>{progress}%</Typography>
        </div>
      </Box>
      {setupItems.map(({ id, icon, iconActive, action, to }, index) => (
        <SetupStage
          key={id}
          action={action}
          to={to}
          icon={index === currentStepIndex ? iconActive : icon}
          stepId={id}
          isCurrentStep={index === currentStepIndex}
          isPastStep={index < currentStepIndex}
        />
      ))}
    </Box>
  );
}
