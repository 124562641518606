export enum UIMode {
  Light = "light",
  Dark = "dark",
}

export enum LocalStorageKeys {
  Token = "arp-web-token",
  RefreshToken = "arp-web-refresh-token",
  UiMode = "arp-ui-mode",
}

export enum Languages {
  EN = "en",
  DE = "de",
  ES = "es",
}

export const KEYCLOAK_REALM = "access";
export const PRICE_RANGE_MAX = 5;

export const modalStyle = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  py: 2,
  px: 2,
};

export enum Cuisine {
  American = "American",
  Chinese = "Chinese",
  French = "French",
  Greek = "Greek",
  Indian = "Indian",
  Italian = "Italian",
  Japanese = "Japanese",
  Korean = "Korean",
  Mexican = "Mexican",
  Thai = "Thai",
  Vietnamese = "Vietnamese",
}

export enum RestaurantPlatforms {
  Resy = "resy",
  OpenTable = "open-table",
  Tock = "tock",
  Eveve = "eveve",
  SevenRooms = "seven-rooms",
}
