import CloseIcon from "@mui/icons-material/Close";
import {
  Backdrop,
  Box,
  Fade,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { modalStyle } from "../../constants";

export default function ImagePreviewModal({
  isOpen,
  handleClose,
  url,
}: {
  isOpen: boolean;
  handleClose: () => void;
  url: string;
}) {
  const { t } = useTranslation();

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="menu-modal-title"
      aria-describedby="menu-modal-description"
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isOpen}>
        <Box sx={modalStyle}>
          <div className="flex justify-end">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <img src={url} alt={t("common.preview")} className="w-full" />
        </Box>
      </Fade>
    </Modal>
  );
}
