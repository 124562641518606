import WestIcon from "@mui/icons-material/West";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  Container,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Paper,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import FileUploader from "../../components/common/FileUploader";
import AccountHeader from "./AccountHeader";
import NoData from "../../components/common/NoData";
import ConfirmModal from "../../components/common/ConfirmModal";

export default function PhotoGallery() {
  const { t } = useTranslation();
  const [files, setFiles] = useState<File[]>([]);
  const { restaurant } = useContext(AppContext);
  const [deleteModal, setDeleteModal] = useState({ index: -1, open: false });

  if (!restaurant) {
    return null;
  }

  const handleClose = () => {
    setDeleteModal({ index: -1, open: false });
  };

  const deleteFile = (index: number) => {
    setDeleteModal({ index, open: false });
  };

  return (
    <div>
      <AccountHeader />
      <Container maxWidth="xl">
        <Link to="/account/profile" aria-label={t("account.profileTitle")}>
          <WestIcon />
        </Link>

        <Typography variant="h6" marginTop={2}>
          {t("photoGallery.title")}
        </Typography>

        <FileUploader
          onFilesSelected={setFiles}
          existingFiles={restaurant.photos}
        />
        <Paper sx={{ paddingX: 8, paddingY: 4, marginTop: 4 }}>
          {restaurant.photos.length ? (
            <ImageList cols={4} gap={20}>
              {restaurant.photos.map((item, index) => (
                <ImageListItem key={index}>
                  <img
                    srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                    alt={item.title}
                    className="rounded-xl"
                    loading="lazy"
                  />
                  <ImageListItemBar
                    sx={{
                      borderRadius: "12px 12px 0 0",
                      background:
                        "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                        "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                    }}
                    position="top"
                    actionIcon={
                      <IconButton
                        onClick={() => setDeleteModal({ index, open: true })}
                        sx={{ color: "white" }}
                        aria-label={t("common.delete")}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                    actionPosition="right"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          ) : (
            <NoData
              title={t("photoGallery.noPhotosTitle")}
              message={t("photoGallery.noPhotosText")}
              topMargin="0"
            />
          )}
        </Paper>
        {deleteModal.open && (
          <ConfirmModal
            handleConfirm={() => deleteFile(deleteModal.index)}
            title={t("common.deleteConfirmTitle")}
            message={t("photoGallery.deleteImage")}
            inputIsOpen={deleteModal.open}
            inputHandleClose={handleClose}
          />
        )}
      </Container>
    </div>
  );
}
