import WestIcon from "@mui/icons-material/West";

import {
  Box,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { MouseEvent, useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import AccountHeader from "./AccountHeader";
import { Cuisine } from "../../constants";
import { Restaurant } from "../../types/Restaurant";

const sectionStyle = { paddingX: 4, paddingY: 6, marginTop: 2 };
export default function ProfileEdit() {
  const { t } = useTranslation();
  const { restaurant } = useContext(AppContext);
  const [updatedRestaurant, setUpdatedRestaurant] = useState<Restaurant>(
    restaurant!,
  );

  const setField = (field: string, value: string) => {
    setUpdatedRestaurant({ ...updatedRestaurant, [field]: value });
  };

  if (!restaurant) {
    return null;
  }

  const handleSubmit = () => {
    console.log("submit");
  };

  const handleScrollTo = (
    e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>,
    id: string,
  ) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div>
      <AccountHeader />
      <Container maxWidth="xl">
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={0} sm={2}>
              <div className="flex-col flex gap-4 mt-24 xs:hidden">
                <Link to="#" onClick={(e) => handleScrollTo(e, "account")}>
                  <Typography variant="body1">
                    {t("profileEdit.accountInfoTitle")}
                  </Typography>
                </Link>
                <Link
                  to="#"
                  onClick={(e) => handleScrollTo(e, "arrival-instructions")}
                >
                  <Typography variant="body1">
                    {t("profileEdit.arrivalInstructionsTitle")}
                  </Typography>
                </Link>
                <Link
                  to="#"
                  onClick={(e) => handleScrollTo(e, "cancellation-policy")}
                >
                  <Typography variant="body1">
                    {t("profileEdit.cancellationPolicyTitle")}
                  </Typography>
                </Link>
              </div>
            </Grid>
            <Grid item xs={12} sm={10}>
              <Link
                to="/account/profile"
                aria-label={t("account.profileTitle")}
              >
                <WestIcon />
              </Link>

              <Typography variant="h6" marginTop={2}>
                {t("profileEdit.title")}
              </Typography>

              <Paper sx={sectionStyle}>
                <Typography variant="h6" id="account">
                  {t("profileEdit.accountInfoTitle")}
                </Typography>
                <Box marginTop={4}>
                  <TextField
                    id="mame"
                    value={updatedRestaurant.name}
                    onChange={(e) => setField("name", e.target.value)}
                    label={t("profileEdit.restaurantName")}
                    required={true}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Box>
                <Box marginTop={1}>
                  <TextField
                    id="bio"
                    multiline
                    value={updatedRestaurant.bio}
                    onChange={(e) => setField("bio", e.target.value)}
                    label={t("profileEdit.bio")}
                    required={true}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Box>
                <Box marginTop={1}>
                  <FormControl fullWidth>
                    <InputLabel id="cuisine-label">
                      {t("profileEdit.cuisine")}
                    </InputLabel>
                    <Select
                      labelId="cuisine-label"
                      id="cuisine"
                      value={updatedRestaurant.cuisine}
                      onChange={(e) => setField("cuisine", e.target.value)}
                    >
                      {Object.values(Cuisine).map((cuisine) => (
                        <MenuItem value={cuisine} key={cuisine}>
                          {cuisine}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box marginTop={1}>
                  <TextField
                    id="website"
                    value={updatedRestaurant.website}
                    onChange={(e) => setField("website", e.target.value)}
                    label={t("profileEdit.website")}
                    required={true}
                    variant="outlined"
                    type="url"
                    fullWidth
                    margin="normal"
                  />
                </Box>
                <Box marginTop={1}>
                  <TextField
                    id="phone"
                    value={updatedRestaurant.phone}
                    onChange={(e) => setField("phone", e.target.value)}
                    label={t("profileEdit.phone")}
                    required={true}
                    type="tel"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Box>
              </Paper>
              <Paper sx={sectionStyle}>
                <Typography
                  variant="h6"
                  marginTop={2}
                  id="arrival-instructions"
                >
                  {t("profileEdit.arrivalInstructionsTitle")}
                </Typography>
                <Box marginTop={4}>
                  <TextField
                    id="arrivalInstructions"
                    multiline
                    value={updatedRestaurant.arrivalInstructions}
                    onChange={(e) =>
                      setField("arrivalInstructions", e.target.value)
                    }
                    label={t("profileEdit.arrivalInstructions")}
                    required={true}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Box>
              </Paper>
              <Paper sx={sectionStyle}>
                <Typography variant="h6" marginTop={2} id="cancellation-policy">
                  {t("profileEdit.cancellationPolicyTitle")}
                </Typography>
                <Box marginTop={4}>
                  <TextField
                    id="cancellationPolicy"
                    value={updatedRestaurant.cancellationPolicy}
                    onChange={(e) =>
                      setField("cancellationPolicy", e.target.value)
                    }
                    label={t("profileEdit.cancellationPolicy")}
                    required={true}
                    multiline
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
}
